import React, {useEffect, useState} from 'react'
import { HTMLContentConverter } from '../components/CMS/Content'

export const Snippets = ({ snippets }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  
  useEffect(() => {
    const categories = new Set();
    snippets.forEach(s => {
      const category = s.category ? s.category.toLowerCase() : "other"
      categories.add(category)
    })
    setCategories([...categories])
  }, [snippets])

  const renderedSnippets = snippets.reduce((result, s, i) => {
    const category = s.category ? s.category.toLowerCase() : "other"
    if (selectedCategory === category) {
      result.push(
        (
          <div key={i} className="snippet">
            <span style={{display:"flex",marginBottom:"20px"}}>
              <h6>{s.category}</h6>
            </span>
            <HTMLContentConverter content={s.snippet} />
          </div>
        )
      )
    }
    return result;
  }, [])

  return (
    <div>
      <h6>Categories</h6>
      <div style={{marginBottom:"20px", marginTop:"20px"}}>
        {categories.map((c, i) => (
          <button className={"snippet-pill" + (selectedCategory === c ? " selected" : "")} key={i} onClick={() => {
            setSelectedCategory(c)
          }}>{c}</button>
        ))}
      </div>
      <div>
        {renderedSnippets.length ? renderedSnippets : <p>Select a filter above to view snippets by category.</p>}
      </div>
    </div>
  )
}