import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Content, { HTMLContent } from '../components/CMS/Content'
import { Snippets } from '../components/Snippets'

export const SnippetsPageTemplate = ({ snippets, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient textured-bg">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content content-body page-content"style={{minHeight:"calc(100vh - 300px)"}}>
              <PageContent className="content" content={content} />
              <Snippets snippets={snippets ? snippets : []} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SnippetsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SnippetsPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} location={location}>
      <SnippetsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        snippets={post.frontmatter.snippets}
      />
    </Layout>
  )
}

SnippetsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SnippetsPage

export const SnippetsPageQuery = graphql`
  query SnippetsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        snippets {
          snippet
          category
        }
      }
    }
  }
`
